import React, { useState, useEffect } from "react";
import Footer from '@components/Footer';
import Navigation from '@components/Navigation';
import "./Home.scss";

const HomeLayout = ({children, page}) => {
  


  return (
    <div id="home-wrapper">
      <Navigation page={page} />
      <main>
        <div className="content">
          { children }
        </div>
      </main>
    </div>
  );
}

export default HomeLayout;
