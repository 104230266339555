import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user_id: false,
  user: false, // The user object (matches firebase state + subscription check)
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.user_id = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserGoal: (state, action) => {
      if (state.user) {
        state.user.goal = action.payload;
      }
    },
    logoutUserData: (state) => {
      state.user_id = false;
      state.user = false;
    },
    checkSubscriptionStatus: (state, action) => {
      /**
       * Check the subscription status of the user
       * Set the local userstore state to active if the subscription is active
       * @param {object} action.payload - The user object
       */
      if (action.payload.promo === 'GOLD') {
        state.user.subscription = 'active';
      } else if (action.payload.subscriptionId) {
        // Fetch the subscription from stripe
        try {
          const response = fetch(`https://metro-motivator-stripe.vercel.app/check-subscription-status?subscription_id=${action.payload.subscriptionId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const data = response.json();
          if (data.status === 'active' || data.status === 'trialing') {
            state.user.subscription = 'active';
            state.user.status_type = data.status;
          } else {
            state.user.subscription = 'inactive';
          }
        } catch (error) {
          console.error("Error checking subscription status:", error);
        }
      }
    },
  },
});

export const { setUser, setUserId, setUserGoal, checkSubscriptionStatus, logoutUserData } = usersSlice.actions;
export const getUser = (state) => state.users.user;
export const getSubscriptionStatus = (state) => state.users.subscription;
export const getSubscriptionId = (state) => state.users.subscriptionId;

export default usersSlice.reducer;
