import React from 'react';
import './footer.scss';
import icoHome from './images/ico-home.svg';

import facebookIcon from './images/facebook-svgrepo-com.svg';
import instagramIcon from './images/instagram-svgrepo-com.svg';
import youtubeIcon from './images/youtube-svgrepo-com.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-wraper">
        <div className="text-container">
          <a href="/" className="footer-logo">
            <img src={icoHome} alt="Home Icon"/>
          </a>
          <p>©2025 MetroMotivator<br /> All Rights Reserved.</p>
        </div>
        <div className="link-container">
          <p className="footer-title">Quick Links</p>
          <a href="/about" className="footer-link">About</a>
          <a href="https://app.getterms.io/view/Bf6aW/tos/en-us" className="footer-link">Cookies</a>
          <a href="https://app.getterms.io/view/Bf6aW/privacy/en-us" className="footer-link">Privacy Policy</a>
          <a href="https://app.getterms.io/view/Bf6aW/tos/en-us" className="footer-link">Terms of Service</a>
        </div>
        <div className="help-container">
          <p className="footer-title">Support</p>
          <a href="/contact" className="footer-link">Contact Us</a>
        </div>
        <div className="extra-container">
          <p className="footer-title">Extra Content</p>
          <a href="https://medium.com/@metromotivator" className="footer-link">Blog</a>
          <a href="https://www.youtube.com/watch?v=heZccJKeosw" className="footer-link">Tutorials</a>
        </div>
        <div className="social-container">
          <p className="footer-title">Follow Us</p>
          <a href="https://www.facebook.com/profile.php?id=61569067787001" className="footer-link">
            <img src={facebookIcon} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/metromotivator/" className="footer-link">
            <img src={instagramIcon} alt="Instagram" />
          </a>
          <a href="https://www.youtube.com/@Metromotivator" className="footer-link">
            <img src={youtubeIcon} alt="YouTube" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;