import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken } from 'firebase/messaging';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCJ0qGgU7gMHhIiY9uMEQYw2gTom2TPsyU",
  authDomain: "metro-motivator.firebaseapp.com",
  projectId: "metro-motivator",
  storageBucket: "metro-motivator.appspot.com",
  messagingSenderId: "7488285017",
  appId: "1:7488285017:web:22da0ba75e4c1774aec448",
  measurementId: "G-GCYJ378XTR"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);

export { db, storage, auth, messaging, analytics };