import React, { useState, useEffect } from "react";
import "./Subscribe.scss";
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch
import { logoutUserData } from '@data/redux/usersSlice';
import { logoutUser } from "@data/firebase/auth";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Practice from "@pages/Admin/Home/Practice/Simple";
import PlanComparison from "../PlanComparison";
import Footer from '@components/Footer';
import Navigation from '@components/Navigation';


const SubscribeLayout = ({children, page}) => {
  const [activeHeader, setActiveHeader] = useState(false);
  const dispatch = useDispatch(); 
  const navigate = useNavigate(); 

  console.log(page);

  const handleScrollTo = (e, targetId) => {
    if(page === 'subscribe'){
      e.preventDefault();
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth"
        });
        setTimeout(() => {
          window.scrollBy({ top: -200,  behavior: "smooth" });
        }, 500);
      }
    }
  };

  const logoutSubmit = async () => {
    try {
      await logoutUser();
      dispatch(logoutUserData()); 
      navigate('/'); 
    } catch (error) {
      console.error('Logout failed:', error.message);
    }
  };

  useEffect(() => {
    document.body.classList.add('home-layout');
    return () => {
      document.body.classList.remove('home-layout');
    };
  }, []);

  return (
    <div id="home-wrapper">
      <Navigation page={page} /> 
      <main>
        <div className="content">

          <div className="practice-timer">
            <div className="half">
              <Practice />
            </div>
          </div>

          {children}
          <data id="compare">
            <PlanComparison />
          </data>
        </div>
      </main>
      
    </div>
  );
}
export default SubscribeLayout;
