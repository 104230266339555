

import React, { useState, useEffect } from 'react';
import "./Navigation.scss";
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch
import { getUser, logoutUserData } from '@data/redux/usersSlice';
import { logoutUser } from "@data/firebase/auth";
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const Navigation = ({ page}) => {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const user_store = useSelector(getUser);

  const [activeHeader, setActiveHeader] = useState(false);

  const dispatch = useDispatch(); 
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoggedIn(user_store ? true : false);
  }, [user_store]);

  const handleScrollTo = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth"
      });
      setTimeout(() => {
        window.scrollBy({ top: -200,  behavior: "smooth" });
      }, 500);
    }
  };

  useEffect(() => {
    document.body.classList.add('home-layout');
    return () => {
      document.body.classList.remove('home-layout');
    };
  }, []);

  const logoutSubmit = async () => {
    try {
      await logoutUser();
      dispatch(logoutUserData()); 
      navigate('/'); 
    } catch (error) {
      console.error('Logout failed:', error.message);
    }
  };

  return (
    <header className={(activeHeader ? `active` : ``)}>
      <div className="content">
        <div className="menu-wrapper">
          { page === 'home' ? (
            <>
              <a href="#home" className="logo"></a>
              <menu>
                <a href="#features" className="btn-menu" onClick={(e) => handleScrollTo(e, 'features')}>Features</a>
                <a href="#plans" className="btn-menu" onClick={(e) => handleScrollTo(e, 'plans')}>Plans</a>
                <a href="#testimonials" className="btn-menu" onClick={(e) => handleScrollTo(e, 'testimonials')}>Testimonials</a>
                <a href="#faq" className="btn-menu" onClick={(e) => handleScrollTo(e, 'faq')}>FAQ</a>
                <a href="#contact" className="btn-menu" onClick={(e) => handleScrollTo(e, 'contact')}>Contact</a>
              </menu>
            </>
          ) : (
            <a href="/home" className="logo"></a>
          )}
          { isLoggedIn === true ? (
            <>
              <a href="#" onClick={ () => logoutSubmit() } className="btn-menu btn-signin">Logout</a>
              {user_store.subscription === 'active' ? (
                <a href="/" className="btn-menu btn-main btn-signup">Open App</a>
              ) : (
                <a href="/subscribe" className="btn-menu btn-main btn-signup">Subscribe</a>
              )}
            </>
          ) : (
            <>
              <a href="/login" className="btn-menu btn-signin">Login</a>
              <a href="/register" className="btn-menu btn-main btn-signup">Sign Up</a>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Navigation;
