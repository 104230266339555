import { auth, db } from "../";
import { logEvent } from "../analytics";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail as firebaseSendPasswordResetEmail } from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { createDocument } from "@data/firebase/firestore/saveData";
import { logoutUserData } from '@data/redux/usersSlice';

// Sign Up
export const createUser = (email, password, data, callback) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((result) => {
      const user = result.user;
      
      // Log user creation success
      console.log('User created:', user);

      // Now save additional user data to Firestore
      const userRef = doc(db, 'users', email);
      setDoc(userRef, {
        ...data,
        email: user.email,
        createdAt: serverTimestamp()
      })
      .then(() => {
        // Firestore document created successfully
        console.log('Firestore user document created successfully');
        logEvent('user_created', { signup_method: 'email' });
        callback({ status: 'success' });
      })
      .catch((error) => {
        // Firestore document creation failed
        console.error('Firestore Error:', error);
        callback({ status: 'error', msg: 'Could not save user data to Firestore' });
      });
    })
    .catch((error) => {
      // Firebase Authentication failed
      let errorCode = error.code;
      let errorMessage = error.message;

      // Log detailed error
      console.error('Firebase Auth Error:', errorCode, errorMessage);
      if (errorCode === "auth/weak-password") {
        callback({ status: 'error', msg: "Weak Password" });
      } else if (errorCode === "auth/email-already-in-use") {
        callback({ status: 'error', msg: "Email already in use" });
      } else {
        callback({ status: 'error', msg: errorMessage });
      }
    });
};


// Login User
export const loginUser = async (email, password, callback) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Fetch user data using UID instead of email
    const usersRef = doc(db, 'users', user.email);  // Use user.uid here
    const userDoc = await getDoc(usersRef);

    if (userDoc.exists()) {
      const user_data = userDoc.data();
      const lastLoginTimestamp = serverTimestamp();

      // Update last login time
      await updateDoc(usersRef, { lastLogin: lastLoginTimestamp });
      const full_name = user_data.firstName + ' ' + user_data.lastName;
      logEvent('user_login', { user_name: full_name });
      callback({ type: 'success', data: { user: user_data } });
    } else {
      callback({ type: 'error', data: 'User document does not exist' });
    }
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;

    if (errorCode === "auth/invalid-email") {
      callback({ type: 'error', data: "Invalid email" });
    } else if (errorCode === "auth/invalid-credential") {
      callback({ type: 'error', data: "Invalid credentials" });
    } else {
      callback({ type: 'error', data: errorMessage });
    }
  }
};

// Logout User
export const logoutUser = () => {
  return signOut(auth)
    .catch((error) => {
      console.error('Logout error:', error.message);
    });
};

// Get Current User Data
export const getCurrentUserData = async (callback) => {
  const user = auth.currentUser;
  if (user) {
    try {
      const usersRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(usersRef);
      if (userDoc.exists()) {
        const user_data = userDoc.data();
        callback({ type: 'success', data: { user: user_data } });
      } else {
        callback({ type: 'error', data: 'User document does not exist' });
      }
    } catch (error) {
      callback({ type: 'error', data: error.message });
    }
  } else {
    callback({ type: 'error', data: 'No user is currently logged in' });
  }
};

// Update User Profile
export const updateProfile = async (userId, updatedData, callback) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, updatedData);
    callback({ type: 'success', data: updatedData });
  } catch (error) {
    callback({ type: 'error', data: error.message });
  }
};

// Send Password Reset Email
export const sendPasswordResetEmail = async (email) => {
  try {
    await firebaseSendPasswordResetEmail(auth, email);
    console.log('Password reset email sent successfully');
  } catch (error) {
    console.error('Error sending password reset email:', error);
    throw error; // Re-throw error to be handled in the calling function
  }
};
