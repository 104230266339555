import React from 'react';
import './about.scss';
import Footer from '@components/Footer';

const About = () => {
  return (
    <div className="about-page">
      <div className="content">
        <h1>About Us</h1>
        <p>
          Welcome to MetroMotivator! We're dedicated to helping musicians of all skill levels enhance their craft and achieve their goals. Whether you're practicing with a metronome or exploring new drills, we provide the tools you need to stay on track and motivated.
        </p>
      </div>
    </div>
  );
};

export default About;