import React from 'react';
import ContactForm from '@components/ContactForm';

const Contact = () => {
  return (
    <div className="contact-page">
      <div className="content">
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;