import React, { useState, useEffect } from 'react';
import "./ContactForm.scss";
import emailjs from '@emailjs/browser';

const ContactForm = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false);
    if (name === '' || email === '' || message === '') {
      setError('Please fill in all fields');
    } else {
      emailjs.send("service_e5p95x9","template_2chp7vb",{
        from_name: name,
        to_name: "Metromotivator", 
        send_to: 'info@metromotivator.com', 
        message: message,
        email: email,
        name: name
      }, '0H26NPT9nwbV_KhHB').then((result) => {
        setCompleted(true);
      }, (error) => {
        console.log(error);
        setError('E-mail service failed.');
      })
    }
  }

  return (
     <div className="contact-form">
      <div className="content">
        <h3>Contact</h3>
        {
          completed ? (
            <span className="description">
              Thank you for your message, we will contact you as soon as possible.
            </span>
          ) : (
            <>
              <span className="description">
                Have a question?<br />You can always contact us filling in this form.
              </span>
              <form onSubmit={handleSubmit}>
                <input 
                  type="text" 
                  onChange={(e) => setName(e.target.value)} 
                  value={name} 
                  placeholder="Your name" 
                />
                <input 
                  type="text" 
                  onChange={(e) => setEmail(e.target.value)} 
                  value={email} 
                  placeholder="Email or phone number" 
                />
                <input 
                  type="text" 
                  onChange={(e) => setMessage(e.target.value)} 
                  value={message} 
                  placeholder="Message" 
                />
                {error && <span className="error">{error}</span>}
                <input className="btn-main" type="submit" value="Send" />
              </form>
            </>
          )
        }
      </div>
    </div>
  );
};

export default ContactForm;
