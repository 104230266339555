import React, { useEffect, useRef, useState, useMemo, useContext } from 'react';
import WaveSurfer from 'wavesurfer.js';
import './MetroWave.scss';
//import audioFile from './audio.mp3';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';
import ZoomPlugin from 'wavesurfer.js/dist/plugins/zoom.esm.js';
import Slider from '@mui/material/Slider';
import Tickbox from '@components/Tickbox';
import { PopupContext } from '@components/Popup';

const MetroWave = ({ audioFile, start=1, stop=5, setIsTimerPlaying, setExtraSave, speed=1 }) => {
  const containerRef = useRef(null);
  const wavesurferRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(speed);
  const [autostartTimer, setAutostartTimer] = useState(false);

  const popupActive = useContext(PopupContext);

  const regionsPlugin = useMemo(() => RegionsPlugin.create(), []);
  const zoomPlugin = useMemo(() => ZoomPlugin.create({ scale: 0.5, maxZoom: 100 }), []);

  const [values, setValues] = useState({ start: start, end: stop, speed: speed })

  useEffect(() => {
    setIsPlaying(false);
  }, [popupActive]);

  useEffect(() => {
    if (wavesurferRef.current) {
      wavesurferRef.current.destroy();
      wavesurferRef.current = null;
    }
    if (!wavesurferRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: containerRef.current,
        waveColor: 'rgb(0 82 255)',
        progressColor: 'rgb(179 204 255)',
        plugins: [regionsPlugin, zoomPlugin],
        audioRate: playbackRate,
        // backend: 'WebAudio',
      });
      console.log('the values are', start, stop);

      wavesurferRef.current.load(audioFile);
      let activeRegion = null;
      let loop = true;

      regionsPlugin.on('region-in', (region) => {
        activeRegion = region;
      });

      regionsPlugin.on('region-out', (region) => {
        if (activeRegion === region && loop) {
          region.play();
        }
      });

      regionsPlugin.on('region-update', updatedRegion => {
        setExtraSave({ start: updatedRegion.start, stop: updatedRegion.end, speed: playbackRate });
        setValues({ start: updatedRegion.start, stop: updatedRegion.end, speed: playbackRate });
        console.log('Updated region:', { start: updatedRegion.start, end: updatedRegion.end, speed: playbackRate });
      });

      wavesurferRef.current.on('click', () => {
        togglePlayPause();
      })
      wavesurferRef.current.on('ready', () => {
        const region = regionsPlugin.addRegion({
          start: start,
          end: stop,
          loop: true,
          color: 'rgba(0, 150, 255, 0.1)',
        });
        setValues({ start: region.start, stop: region.end, speed: playbackRate });
        setExtraSave({ start: region.start, end: region.end, speed: playbackRate });
      });
    }
  }, [audioFile]);

  useEffect(() => {
    if (!wavesurferRef.current) return;

    if (!wavesurferRef.current.isPlaying() && isPlaying) {
      wavesurferRef.current.play().catch(e => console.error(e));
      if(autostartTimer) {
        setIsTimerPlaying(isPlaying);
      }
    } else if(wavesurferRef.current.isPlaying() && !isPlaying) {
      wavesurferRef.current.pause();
      if(autostartTimer) {
        setIsTimerPlaying(isPlaying);
      }
    }
  }, [isPlaying, autostartTimer, setIsTimerPlaying]);

  useEffect(() => {
    setPlaybackRate(speed)
  }, [speed]);

  useEffect(() => {
    console.log({ ...values, speed: playbackRate });
    setExtraSave({ ...values, speed: playbackRate });
  }, [playbackRate])

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleSpeedChange = (e, newValue) => {
    setPlaybackRate(newValue);
    wavesurferRef.current.setPlaybackRate(newValue);
  };

  return (
    <div className="metrowave-wrapper">
      <div ref={containerRef} id="wave-audio" />
      <div className="playback-wrapper">
        <label style={{ width: 50 }}>
          <span id="rate">{playbackRate.toFixed(2)}</span>x
        </label>
        <div style={{ width: '100%'}}>
          <Slider
            value={playbackRate}
            onChange={handleSpeedChange}
            min={0.2} max={2} step={0.01}
            //marks={[{ value: 0.2, label: '0.2x' },{ value: 2, label: '2x' }]}
            aria-labelledby="playback-rate-slider"
            valueLabelDisplay="auto"
          />
        </div>
      </div>
      <Tickbox checked={autostartTimer} onChange={e => setAutostartTimer(!autostartTimer) } label={(isPlaying ? 'Stop' : 'Start') + " timer with audio."} />
      <div id="playToggle" onClick={togglePlayPause} className={isPlaying ? 'btn-main full-width big stop' : 'btn-main full-width big play'}>
        <i></i><span>{isPlaying ? 'Stop' : 'Play'}</span>
      </div>
    </div>
  );
};

export default MetroWave;
