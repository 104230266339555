import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, setUser } from '@data/redux/usersSlice';
import Authentication from '@data/routes';
import Loading from '@components/Loading';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from '@data/redux/store'; 
import { auth } from '@data/firebase';
import { getDocument } from '@data/firebase/firestore/getData';
import { logoutUserData } from '@data/redux/usersSlice';
import Footer from '@components/Footer';

function App() {
  const [authUser, setAuthUser] = useState(null);
  const user_store = useSelector(getUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user_store) {
      setAuthUser(user_store);
    } else {
      setAuthUser(false);
    }
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (!user_store) {
          getDocument('users', user.email, (response) => {
            if (response.data && response.data.user) {
              dispatch(setUser(response.data.user));
              setAuthUser(response.data.user);
            }
          });
        }
      } else {
        setAuthUser(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [user_store, dispatch]);

  return (
    <PersistGate loading={<Loading />} persistor={persistor}>
      {authUser !== null ? <Authentication signed={authUser} /> : <Loading />}
      <Footer />
    </PersistGate>
  );
}

export default App;