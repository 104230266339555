// ROUTES
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getUser } from "@data/redux/usersSlice";

/*#####LAYOUTS########################################*/
import WebLayout from "@components/Layouts/Web";
import AdminLayout from "@components/Layouts/Admin";
import HomeLayout from "@components/Layouts/Home";
import SubscribeLayout from "@components/Layouts/Subscribe";

/*#####PAGES########################################*/
import WebHome from "@pages/Web/Home";
import WebAbout from '@pages/Web/About';
import WebLogin from "@pages/Web/Login";
import WebContact from '@pages/Web/Contact';
import WebRegister from "@pages/Web/Register";
import WebSubscribe from "@pages/Web/Subscribe";
import WebForgotPassword from "@pages/Web/ForgotPassword";

import AdminHome from "@pages/Admin/Home";
import AdminGoals from "@pages/Admin/Goals";
import AdminHistory from "@pages/Admin/History";
import AdminDrills from "@pages/Admin/Drills";
import AdminAdmin from "@pages/Admin/Admin";
import AdminPractice from "@pages/Admin/Home/Practice";

export default function Authentication({ signed }) {
  const user_store = useSelector(getUser);

  const RenderLayout = (layout, element, page = 'home') => {
    if (layout === 'web') return <WebLayout>{element}</WebLayout>;
    if (layout === 'admin') return <AdminLayout page={page} signed={signed}>{element}</AdminLayout>;
    if (layout === 'home') return <HomeLayout page={page}>{element}</HomeLayout>;
    if (layout === 'subscribe') return <SubscribeLayout page={page}>{element}</SubscribeLayout>;
  };

  return (
    <Routes>
      {signed ? (
        user_store.subscription === 'active' ? (
          <>
            <Route exact path={'/'} element={RenderLayout('admin', <AdminPractice />, 'home')} />
            <Route exact path={'/goals'} element={RenderLayout('admin', <AdminGoals />, 'goals')} />
            <Route exact path={'/practice'} element={RenderLayout('admin', <AdminPractice />)} />
            <Route exact path={'/history'} element={RenderLayout('admin', <AdminHistory />, 'history')} />
            <Route exact path={'/drills'} element={RenderLayout('admin', <AdminDrills />, 'drills')} />
            <Route exact path={'/admin'} element={RenderLayout('admin', <AdminAdmin />, 'admin')} />
          </>
        ) : (
          <>
            <Route exact path={'/'} element={RenderLayout('subscribe', <WebSubscribe />, 'subscribe')} />
            <Route exact path={'/subscribe'} element={RenderLayout('subscribe', <WebSubscribe />, 'subscribe')} />
            <Route exact path={'/home'} element={RenderLayout('home', <WebHome />, 'home')} />
          </>
        )
      ) : (
        <>
          <Route exact path={'/'} element={RenderLayout('home', <WebHome />, 'home')} />
          <Route exact path={'/forgot-password'} element={RenderLayout('web', <WebForgotPassword />)} />
          <Route exact path={'/register'} element={RenderLayout('web', <WebRegister />)} />
        </>
      )}

      {/* Default routes */}
      <Route exact path={'/about'} element={RenderLayout('home', <WebAbout />, 'about')} />
      <Route exact path={'/contact'} element={RenderLayout('home', <WebContact />, 'contact')} />
      <Route exact path={'/login'} element={RenderLayout('web', <WebLogin />)} />
      <Route path="*" element={<Navigate to="/" />} /> {/* Redirect to home - TODO: make a 404 page*/}

    </Routes>
  );
}
